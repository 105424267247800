/**
 *
 * @param config IHttpConfig
 */
export default async function Http({
  method = "GET",
  url,
  body,
  header,
  token,
}) {
  const config = { method, url, body, header };

  config.url = `${config.url||''}`.trim();

  config.token = token;

  // parse data to url if method is a GET
  if (config.method === "GET") {
    // append all data entries to url to form a proper get request
    config.url += config.body
      ? `?${Object.entries(config.body)
          .map((e) => `${e[0]}=${e[1]}`)
          .join("&")}`
      : "";
  }
  return _fetch(config);
}

async function _fetch({ ...config }) {
  const body =
    config.method === "POST" ? JSON.stringify(config.body) ?? {} : undefined;

  const authorization = config.token || sessionStorage.getItem("token");

  const header = {
    "Content-Type": "application/json",
    Authorization: authorization ?? "",
    ...config.header,
  };
  if (!header["Authorization"]) {
    delete header["Authorization"];
  }

  return await fetch(config.url, {
    method: `${config.method}`.toLowerCase(),
    body: body,
    redirect: "follow",
    timeout: 30 * 1000,
    headers: header,
  })
    .then(checkStatus)
    .catch(error);
}

// status handler
async function checkStatus(res) {
  const json = await res.json();
  if (res.ok) {
    return {
      code: res.status,
      status: true,
      message: json.message || res.statusText,
      json: json,
      data: json.data || json,
    };
  }
  return {
    code: res.status,
    status: false,
    json: json,
    message: json.message || res.statusText,
    data: json.data || json,
  };
}

// error handler
function error(err) {
  return   {
    message: err.message || "check network connection",
    code: 500,
    status: false,
    data: null,
  }.toJson();
}
